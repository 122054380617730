import { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { assessmentIdAtom, progressionsAtom, stepIndexAtom } from "_atoms";
import {
    batchIdSelector,
    currentStepIdSelector,
    hasStartedAssessmentSelector,
    hasStartedBatchSelector,
    hasStartedCurrentStepSelector,
    isTimerPausedSelector,
    isLastMaterialSelector,
    previousStepIdSelector,
    respondentIdSelector
} from "_selectors";
import { createProgression, mergeProgressions } from "_helpers/progression-helpers";

/** On completing a step, append progressions of the step completed and any step being started. */
const useStepIndex = () => {
    /** @type ?string */
    const assessmentId = useRecoilValue(assessmentIdAtom);
    /** @type ?string */
    const batchId = useRecoilValue(batchIdSelector);
    /** @type ?string */
    const currentStepId = useRecoilValue(currentStepIdSelector);
    /** @type boolean */
    const hasStartedAssessment = useRecoilValue(hasStartedAssessmentSelector);
    /** @type boolean */
    const hasStartedCurrentStep = useRecoilValue(hasStartedCurrentStepSelector);
    /** @type boolean */
    const hasStartedBatch = useRecoilValue(hasStartedBatchSelector)
    /** @type boolean */
    const isLastMaterial = useRecoilValue(isLastMaterialSelector);
    /** @type boolean */
    const isTimerPaused = useRecoilValue(isTimerPausedSelector);
    /** @type ?string */
    const previousStepId = useRecoilValue(previousStepIdSelector);
    /** @type ?string */
    const respondentId = useRecoilValue(respondentIdSelector);
    /** @type Survey.Index */
    const stepIndex = useRecoilValue(stepIndexAtom);
    /** @type Dispatch<SetStateAction<Progression[]>> */
    const setProgressions = useSetRecoilState(progressionsAtom);

    /** When moving forward beyond a previous step, record the previous step as completed */
    useEffect(() => {
        // This hook only runs when a respondent finishes a step. Sometimes completing a step results in simultaneously
        // starting the next one. When that happens, this hook adds both progression objects simultaneously to the
        // progressions atom. Adding both simultaneously avoids competing/cancelled back-to-back API calls that would
        // happen if two separate additions were made to the progressions array.
        if (stepIndex.value < 1 || stepIndex.direction !== 1) return;

        /** @type Date */
        const createdAt = new Date();
        /** @type {?string} - When the assessment hasn't yet been started, record it at started */
        const assessmentStarted = hasStartedAssessment ? null : assessmentId;
        /** @type {?string} - When the batch hasn't yet been started, record it as started */
        const batchStarted = hasStartedBatch ? null : batchId;
        /** @type {?string} - When the step hasn't yet been started, record it as started */
        const stepStarted = hasStartedCurrentStep ? null : currentStepId;
        /** @type Progression */
        const startProgression = createProgression(
            createdAt, respondentId, stepStarted, 'S', assessmentStarted, batchStarted
        );
        /** @type {?string} - When moving beyond the last step of the assessment, record the assessment as completed */
        const assessmentCompleted = !currentStepId ? assessmentId : null;
        /** @type {?string} - When a completed assessment is also the last material in the batch, record the batch as completed */
        const batchCompleted = isLastMaterial && !!assessmentCompleted ? batchId : null;
        /** @type Progression */
        const completionProgression = createProgression(
            createdAt, respondentId, previousStepId, 'C', assessmentCompleted, batchCompleted
        );
        /** boolean */
        const isStartingNextStep = !isTimerPaused && !!stepStarted;
        /** @type Progression[] */
        const newProgressions = isStartingNextStep ? [completionProgression, startProgression] : [completionProgression];

        setProgressions((prev) => mergeProgressions(newProgressions, prev));
    }, [assessmentId, batchId, currentStepId, hasStartedAssessment, hasStartedBatch,
        hasStartedCurrentStep, isLastMaterial, isTimerPaused, previousStepId, respondentId, setProgressions, stepIndex]);
};

export { useStepIndex };