import axios from "axios";
import mem from "mem";
import { constants } from '../constants'

/**
 * Refresh the JWT access token
 * @return {Promise} - Returns the axios request promise.
 */
const refreshSession = async() => {
    try {
        /** @type {Survey.Session} - The session stored in session storage */
        const previousSession = JSON.parse(sessionStorage.getItem("session"));
        const requestOptions = {
            method: 'POST',
            url: `${constants.apiUrl}/token/refresh/`,
            data: {refresh: previousSession?.refreshToken},
            headers: {
                Accept: "application/json",
            },
            timeout: 10000,
            withCredentials: false,
            responseType: 'json',
            responseEncoding: 'utf8'
        };
        const response = await axios(requestOptions);

        if (!response?.data?.access) {
            sessionStorage.removeItem('session');
            return null;
        } else {
            const refreshedSession = {...previousSession, accessToken: response?.data?.access};
            const refreshedSessionJson = JSON.stringify(refreshedSession);
            sessionStorage.setItem('session', refreshedSessionJson);
            return refreshedSession;
        }
    } catch (error) {
        sessionStorage.removeItem('session');
    }
}

const maxAge = 10000;

export const memoizedRefreshedSession = mem(refreshSession, { maxAge })