import { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { stepIndexAtom, timeRemainingAtom } from "_atoms";
import { currentStepSelector, startTimeOfCurrentStepSelector } from "_selectors";
import { createTimeRemainingText } from "_helpers/time-remaining-helpers"

/**
* Listen for changes in the startTime or timeLimit. When a timer is required (i.e., the step has a non-zero time
* limit and a start time), set an interval to automatically generate timeRemainingText once a second showing the time
* remaining. Store that timeRemainingText in a recoil atom for display in the timer container.
*/
const useTimer = () => {
    /** @type ?Step */
    const currentStep = useRecoilValue(currentStepSelector);
    /** @type Date */
    const startTime = useRecoilValue(startTimeOfCurrentStepSelector);
    /** @type Dispatch<SetStateAction<string>> */
    const setTimeRemaining = useSetRecoilState(timeRemainingAtom);
    /** @type Dispatch<SetStateAction<Survey.Index>> */
    const setStepIndex = useSetRecoilState(stepIndexAtom);
    /** @type {number} - Maximum number of seconds a respondent can take after starting the step */
    const timeLimit = currentStep?.timeLimit || 0;

    /**
    * Set an interval to update the timeRemaining atom once per second when there is a time limit.
    */
    useEffect(()=>{
        // When the step lacks a time limit or start time, no timer is required. Set the timeRemaining text to null.
        if (!timeLimit || !startTime) {
            setTimeRemaining(null);
            return;
        }

        /** @type {Date} - Calculate the deadline for completing materials based on the start time and time limit */
        const endTime = new Date(startTime + timeLimit*1000);

        /** @type {number} - Set an interval to automatically refresh the timeRemainingText twice a second */
        let timeRemainingInterval = setInterval(() => {
            /** @type Date */
            const currentTime = new Date();
            /** @type string */
            const timeRemainingText = createTimeRemainingText(currentTime, endTime);
            setTimeRemaining(timeRemainingText);

            // When the deadline is reached, advance the stepIndex
            if (currentTime > endTime) {
                setStepIndex((prev) => (
                    /** @type Survey.Index */
                    {
                        direction: 1,
                        value: prev + 1
                    }
                ));
            }
        }, 1000);

        // When the effect unmounts, clear the interval to prevent memory leaks
        return () => {
            clearInterval(timeRemainingInterval);
        };
    }, [setStepIndex, setTimeRemaining, startTime, timeLimit]);
}

export { useTimer };