import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from 'recoil';
import { answersAtom } from "_atoms";
import { isTimeToSaveAnswersSelector } from "_selectors"
import { createRevisedListAfterSave, getUnsavedReplies } from "_helpers/reply-helpers";
import { answersPostQuery } from "_queries/answers-queries";
import useAxiosError from "_actions/axios-error-actions";

/**
* When the list of answers changes, check if it is time to post unsaved ones to the API.
*/
const useAnswers = () => {
    /** @type {[Answer[], Dispatch<SetStateAction<Answer[]>>]} */
    const [answers, setAnswers] = useRecoilState(answersAtom);
    /** @type boolean */
    const isTimeToSaveAnswers = useRecoilValue(isTimeToSaveAnswersSelector)

    /** Actions to handle axios errors */
    const { handleAxiosError } = useAxiosError();

    /**
     * Whenever the list of answers changes, check if it's time to post the unsaved ones
     */
    useEffect(() => {
        /** @type {AbortController} - Use an abort controller to cancel API requests if the component unmounts */
        const controller = new AbortController();

        /**
        * After answers are posted to the database, update the list of answers in the app state with
        * isSaved property set to true for all the newly saved ones.
        * @param {AxiosResponse} axiosResponse - The axios response object returned after the API call
        * @return {void}
        */
        function handleSuccess(axiosResponse) {
            try {
                const revisedAnswers = createRevisedListAfterSave(axiosResponse.data.results, answers);
                setAnswers(revisedAnswers);
            } catch (error) {
                console.log(error);
            }
        }

        // When it's time to save, post any unsaved answers to the API
        if (isTimeToSaveAnswers) {
            /** @type {(Answer|Demographic)[]} */
            const unsavedAnswers = getUnsavedReplies(answers);
            answersPostQuery(unsavedAnswers, controller).then(handleSuccess).catch(handleAxiosError);
        }

        // When the effect unmounts, abort any active API requests
        return () => {
            controller.abort();
        };
    }, [answers, handleAxiosError, isTimeToSaveAnswers, setAnswers]);
}

export { useAnswers };