import { atom } from "recoil";

/**
 * A user object describing someone who can log in to gain additional permissions
 */
const userAtom = atom({
  key: 'user',
  default:
  /** @type Survey.User */
  {
    id: '',
    accountUserRelations: [],
    customId: '',
    createdAt: '',
    emailAddress: '',
    issuer: '',
    firstName: '',
    isActive: true,
    isStaff: false,
    lastName: '',
    respondents: [],
    url: ''
  }
});

export { userAtom };