import { shuffle } from "shuffle-seed";

/**
 * When a step's isRandomized property is true, randomize the order of its items using a specified random seed value.
 * @param {Step} step - The assessment step for which the order of items is being randomized
 * @param {Integer} randomSeed - The respondent's random seed to use in ordering materials
 * @return {Step} - Returns a new Step object identical the original but with the modified order of items
 */
function applyRandomSeedToStep(step, randomSeed) {
    // When there's no randomization, return the unmodified step
    if (!step?.isRandomized) return step;

    const shuffledItems = shuffle(step.items, randomSeed);
    return {...step, items: shuffledItems}
}

/**
 * For a given assessment, randomize the order of items within all steps that have the isRandomized property set to true.
 * @param {Assessment} assessment - The assessment being randomized
 * @param {Integer} randomSeed - The respondent's random seed to use in ordering items within the assessment
 * @return {Assessment} - Returns a new Assessment object identical the original but with the modified Step objects
 */
export function applyRandomSeedToAssessment(assessment, randomSeed) {
    // Check that there is a random seed and items to shuffle
    if (!randomSeed || !assessment?.steps?.length) return assessment;

    const stepsAfterItemsShuffled = assessment.steps.map((step) =>
        applyRandomSeedToStep(step, randomSeed)
    );
    return {...assessment, steps: stepsAfterItemsShuffled}
}

/**
 * Find the index of the first step on the assessment that lacks a completion progression object.
 * @param {Assessment} assessment - The assessment being randomized
 * @param {Progression[]} progressions - The list of the progression objects describing materials started/completed thus far
 * @return {Integer} - Returns the index of the first uncompleted step on the assessment. When there are no uncompleted
 *                     steps, returns -1.
 */
export function findIndexOfFirstUncompletedStep(assessment, progressions) {
    // When there are no steps in the assessment, there are no uncompleted steps
    if (!assessment?.steps?.length || !progressions?.length) return -1;

    return assessment.steps.findIndex((step) => !progressions.some((progression) =>
        progression.step === step.id &&
        progression.type === 'C' &&
        !progression.deletedAt
    ));
}

/**
 * For a given step, remove any fields related to saving the respondent's login code
 * @param {Step} step - The assessment step for which fields are being filtered
 * @return {Step} - Returns a new Step object identical the original but with login code fields filtered out
 */
function removeLoginCodeFieldsFromStep(step) {
    const filteredFields = step.fields.filter((field) => field.category !== 'LER');
    return {...step, fields: filteredFields}
}

/**
 * For a given assessment, remove any fields related to saving the respondent's login code
 * @param {Assessment} assessment - The assessment from which to remove login code fields
 * @return {Assessment} - Returns a new Assessment object identical the original but with the modified Step objects
 */
export function removeLoginCodeFieldsFromAssessment(assessment) {
    const stepsAfterFieldsFiltered = assessment.steps.map((step) => removeLoginCodeFieldsFromStep(step));
    return {...assessment, steps: stepsAfterFieldsFiltered}
}