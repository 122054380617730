import { useEffect } from "react";
import {useRecoilValue, useResetRecoilState, useSetRecoilState} from 'recoil';
import { elementIndexAtom, stepIndexAtom } from "_atoms";
import {currentStepMaxElementIndexSelector, previousStepMaxElementIndexSelector} from "_selectors";

/**
 * When the element index changes, check if the new value requires a change in the step index.
 * (1) Element index going beyond the number of elements in the step triggers an increase in the step index
 * (2) Element index falling below 0 triggers a decrease in the step index
 */
const useElementIndex = () => {
    /** @type number */
    const currentStepMaxElementIndex = useRecoilValue(currentStepMaxElementIndexSelector);
    /** @type Survey.Index */
    const elementIndex = useRecoilValue(elementIndexAtom);
    /** @type number */
    const previousStepMaxElementIndex = useRecoilValue(previousStepMaxElementIndexSelector);
    /** @type Dispatch<SetStateAction<Survey.Index>> */
    const setElementIndex= useSetRecoilState(elementIndexAtom);
    /** @type Dispatch<SetStateAction<Survey.Index>> */
    const setStepIndex= useSetRecoilState(stepIndexAtom);
    /** @type function */
    const resetElementIndex = useResetRecoilState(elementIndexAtom)

    /** When the element index goes beyond the number of elements in the step, increase the step index and reset
     * the element index  */
    useEffect(() => {
        // Can exit when the element index hasn't yet reached the max or there is no step loaded (maxElementIndex = -1)
        if (elementIndex.value <= currentStepMaxElementIndex || currentStepMaxElementIndex < 0) return;

        setStepIndex((prev) => (
            /** @type Survey.Index */
            { direction: 1, value: prev.value + 1 }
        ));
        resetElementIndex();
    }, [elementIndex, currentStepMaxElementIndex, setStepIndex, resetElementIndex]);

    /** When the element index falls below zero and there is a previous step to return to, (1) decrease the step index
     * and (2) set the element index to the last element of the previous step */
    useEffect(() => {
        if (elementIndex.value > -1 || previousStepMaxElementIndex < 0) return;

        setStepIndex((prev) => (
            /** @type Survey.Index */
            {direction: -1, value: prev.value - 1}
        ));
        setElementIndex(
            /** @type Survey.Index */
            { direction: -1, value: previousStepMaxElementIndex}
        );
    }, [elementIndex, previousStepMaxElementIndex, setElementIndex, setStepIndex]);

}

export { useElementIndex };