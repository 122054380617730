import { useEffect } from "react";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { elementIndexAtom, stepIndexAtom } from '_atoms';
import { assessmentSelector, indexOfFirstUncompletedStepSelector} from "_selectors";

/**
 * When an assessment first loads, set the item and step index. Show the first element of the first uncompleted step.
 * When there are no uncompleted steps, show the first element of the first step.
 */
const useAssessment = () => {
    /** @type {Assessment|T} */
    const assessment = useRecoilValue(assessmentSelector);
    /** @type number */
    const indexOfFirstUncompletedStep = useRecoilValue(indexOfFirstUncompletedStepSelector);
    /** @type function */
    const resetElementIndex = useResetRecoilState(elementIndexAtom);
    /** @type Dispatch<SetStateAction<Survey.Index>> */
    const setStepIndex = useSetRecoilState(stepIndexAtom);

    useEffect(() => {
        // Only proceed when there is an active assessment with step information available (so we can know the
        // completion status of those steps)
        if (!assessment?.steps?.length) return;

        /** @type {number} - When there are no uncompleted steps (index of first uncompleted step is -1), show the first
         * step in the assessment (i.e., set the step index to 0). */
        const stepIndex = Math.max(indexOfFirstUncompletedStep, 0);

        setStepIndex(
            /** @type Survey.Index */
            { direction: 1, value: stepIndex }
        );
    }, [assessment, indexOfFirstUncompletedStep, resetElementIndex, setStepIndex]);
}

export { useAssessment };