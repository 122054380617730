import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from 'recoil';
import { languageAtom, respondentAtom } from "_atoms";

/**
 * Use the language atom to (1) keep the i18n language in sync, (2) keep the localStorage value in sync, (3)
 * set the language property of the respondent atom, and (4) when the respondent already has a language property,
 * make sure the value of the language atom is consistent with it.
 */
const useLanguage = () => {
    /** @type {[Respondent, Dispatch<SetStateAction<Respondent>>]} */
    const [respondent, setRespondent] = useRecoilState(respondentAtom);
    /** @type string */
    const [language, setLanguage] = useRecoilState(languageAtom);
    /** @type i18n */
    const { i18n } = useTranslation();
    /** @type {string} - When the respondent atom has a language property, use it. Otherwise, use the value of the
     * language atom.*/
    const languageToUse = respondent?.language || language;

    /**
     * When the respondent chooses a language for the first time, update the language property of their respondent atom.
     */
    useEffect(() => {
        // Can exit if there is no language to use, or there isn't a respondent yet, or the respondent already
        // has a language property
        if (!languageToUse || !respondent?.id || !!respondent?.language) return;

        setRespondent((prev) => (
            {
                ...prev,
                language: languageToUse,
                isSaved: false
            }
        ))
    }, [languageToUse, respondent, setRespondent]);

    /**
     * Keep the i18n language and localStorage values in sync with the correct language to use
     */
    useEffect(() => {
        // Can exit if there is no language to use
        if (!languageToUse) return;

        // Keep the i18n language in sync with the appropriate language to use
        i18n.changeLanguage(languageToUse);

        //Create a localStorage entry to persist the language between login sessions.
        localStorage.setItem('i18nextLng', languageToUse);
    }, [i18n, languageToUse]);

    /**
     * Make sure the language atom is consistent with the value of the respondent's language property
     */
    useEffect(() => {
        // Can exit when the language atom is already correct
        if (language === languageToUse) return;

        setLanguage(languageToUse);
    }, [language, languageToUse, setLanguage]);

}

export { useLanguage };