import { atom } from 'recoil';

/**
 * Which element on the active step should be rendered. Also describes the direction of movement (1 or -1).
 */
const elementIndexAtom = atom({
    key: 'elementIndex',
    default: (
        /** @type Survey.Index */
        {
            direction: 1,
            value: 0
        }
    )
});

export { elementIndexAtom };