import { useEffect } from "react";
import { useErrorBoundary } from 'react-error-boundary';
import { useRecoilState } from 'recoil';
import { respondentAtom } from "_atoms";
import { respondentPutQuery } from "_queries/respondent-queries";

/** When the respondent atom changes, save the changes to the API */
const useRespondent = () => {
    /** @type {Respondent, Dispatch<SetStateAction<Respondent>>} */
    const [respondent, setRespondent] = useRecoilState(respondentAtom);

    /** Hook provided by react-error-boundary for sharing errors with the nearest boundary */
    const { showBoundary } = useErrorBoundary();

    useEffect(() => {
        // Only proceed when the respondent object is flagged as having unsaved changes (isSaved=false)
        if (respondent?.isSaved === true) return;

        /**
         * Handle an error from an unsuccessful API call or i18n language setter attempt.
         * @param {object} e - The error object
         * @return {void}
         */
        function handleFailure(e) {
            console.log({'error': e});
            const errorMessage = e?.response?.data?.message || e?.data?.message || e;
            showBoundary(errorMessage);  //Display the nearest error boundary
        }

        /**
         * After a successful API call, save the new respondent object and set its isSaved property to true
         * @param {AxiosResponse} axiosResponse - The http response object from axios
         * @return {void}
         */
        function handleSuccess(axiosResponse) {
            try {
                setRespondent({
                    ...axiosResponse.data,
                    isSaved: true
                });
            }  catch (error) {
                handleFailure(axiosResponse);
            }
        }

        /** @type AbortController */
        const controller = new AbortController();
        respondentPutQuery(respondent, controller).then(handleSuccess).catch(handleFailure);
    }, [respondent, setRespondent, showBoundary]);
}

export { useRespondent };