import React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { assessmentIdAtom } from "_atoms";
import { materialsSelector, welcomeSelector } from "_selectors";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import CardBodyMarkdown from "_components/CardBodyMarkdown/CardBodyMarkdown";
import CardFooter from "_components/CardFooter/CardFooter";
import CardHeader from "_components/CardHeader/CardHeader";
import './Welcome.css';

const Welcome = () => {
    /** @type Material[] */
    const materials = useRecoilValue(materialsSelector);
    /** @type Dispatch<SetStateAction<string>> */
    const setAssessmentId = useSetRecoilState(assessmentIdAtom);
    /** @type Welcome */
    const welcome = useRecoilValue(welcomeSelector);

    const navigate = useNavigate();

    const clickHandler = () => {
        if (materials?.length > 1) {
            navigate("/overview");
        } else {
            setAssessmentId(materials?.[0]?.assessment);
        }
    }

    return (
        <Container className="fade-in">
            <Card className="welcome">
                <CardHeader value={welcome?.header}/>
                <CardBodyMarkdown value={welcome?.body}/>
                <CardFooter buttonLabel={welcome?.buttonLabel} onClick={clickHandler}/>
            </Card>
        </Container>
    );
}

export default Welcome;