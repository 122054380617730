/**
 * Check if a timer is paused for a given step
 * @param {Step} step - The step to check
 * @param {number} elementIndex - Positive integer indicating the position of the active element within the step
 * @return {boolean}
 */
export function checkIfTimerIsPaused(step, elementIndex) {
    /** @type number */
    const numberOfInstructions = step?.instructions?.length || 0;
    /** @type number */
    const indexOfLastInstruction = numberOfInstructions - 1;

    // The timer can only pause during instructions. If the element index is past the instructions, return false.
    if (elementIndex > indexOfLastInstruction) return false;

    /** @type {boolean} - Return the isTimerPaused property of current instruction */
    return !!step?.instructions?.[elementIndex]?.isTimerPaused;
}

/**
 * Find the index of the first element on the same page as a given elementIndex
 * @param {(Item|Instruction|Field)[]} elements - The list of elements (instructions, items, fields) in which to search
 * @param {Integer} elementIndex - Positive integer indicating the position of the active element
 * @return {number} - Returns the index of the first element on the page
 */
function findIndexOfFirstElementOnPage(elements, elementIndex) {
    /** {(Item|Instruction|Field)[]} - findIndex only goes in ascending order, so need to reverse the array of elements */
    const reversed = [...elements].reverse();
    /** {number} - The highest index of any element on the step */
    const maxIndex = elements.length - 1;
    /** {number} - Distance from the current element index from the end of the array */
    const reversedIndex = maxIndex - elementIndex;
    /** {number} - The first element on the current page is the one that (1) comes before or matches the elementIndex,
     *  and (2) follows a page break. */
    const reversedIndexOfMatchingElement = reversed.findIndex((element, index) => {
         return element?.isAfterPageBreak === true && index >= reversedIndex
    });
    /** @type {number} - Need to un-reverse index */
    const indexOfMatchingElement = maxIndex - reversedIndexOfMatchingElement;
    /** {number} - An element index cannot drop below zero */
    const minimumElementIndex = 0;

    return Math.max(minimumElementIndex, indexOfMatchingElement);
}

/**
 * Find the index of the last element on the same page as a given element index
 * @param {(Item|Instruction|Field)[]} elements - The list of elements in which to search
 * @param {number} elementIndex - Positive integer indicating the position of the active element within the array of elements
 * @return {number} - Returns the index of the last element on the same page as the given element index
 */
function findIndexOfLastElementOnPage(elements, elementIndex) {
    /** @type {number} - Find the element after a page break that comes after the current element index */
    const indexOfFirstElementOnNextPage = elements.findIndex((element, index) =>
       !!element?.isAfterPageBreak && index > elementIndex
    );
    /** @type {number} */
    const indexOfLastElementInStep = elements.length - 1;

    return indexOfFirstElementOnNextPage === -1 ? indexOfLastElementInStep : indexOfFirstElementOnNextPage - 1;
}

/**
 * Create a page object defining which elements to display on the screen and the start and end index of those elements
 * @param {number} elementIndex - Positive integer indicating the position of the active element (value of elementIndexAtom)
 * @param {Step} step - The step for which to create the page
 * @return {[(Instruction|Item|Field)[], number, number]}
 */
export function filterPageElements(elementIndex, step) {
    /** @type {Item[]} - Get a list of items on the step */
    const items = step?.items || [];
    /** @type {Field[]} - Get a list of fields on the step */
    const fields = step?.fields || [];
    /** @type {Instruction[]} - Get a list of instructions on the step */
    const instructions = step?.instructions || [];
    /** @type {(Instruction|Item|Field)[]} - Append the list of fields to the list of items */
    const elements = [...instructions, ...items, ...fields];
    /** @type {number} - The first element on the current page (1) comes before or is this one, and (2) follows a page break. */
    const startIndex = findIndexOfFirstElementOnPage(elements, elementIndex);
    /** @type {number} - The first element on the next page (1) comes after the current index, and (2) follows a page break */
    const endIndex = findIndexOfLastElementOnPage(elements, elementIndex);
    /** @type {(Instruction|Item|Field)[]} */
    const elementsOnPage = elements.filter((element, index) =>
        (index >= startIndex) && (index <= endIndex)
    );

    return [elementsOnPage, endIndex, startIndex];
}